import { LoaderFunctionArgs, useLoaderData } from 'react-router'
import { requireNotAuthenticated } from '~/auth/session.server'
import { defaultGrid, gridChildClasses } from '~/utils/layout'
import classNames from 'classnames'
import { ExternalLinkIcon } from '~/theme/icons'
import { ROUTES } from '~/routes'
import { buttonIconClasses, ButtonLink } from '~/components/Button'
import {
  BackgroundElements,
  PageLayoutWithoutHeader,
} from '~/components/Layout'
import {
  ClearedErrorData,
  getApiErrorMessage,
  isErrorCodeKnown,
} from '~/api/apiError'
import { ActionErrorAlert } from '~/components/ActionErrorAlert'
import { focusRing } from '~/utils/styles'
import { JovivaLogo } from '~/components/JovivaLogo'
const SANI_AKTUELL_LOGO = '/logos/sani_aktuell_logo.jpg'

const strings = {
  headline: 'Jetzt anmelden',
  description:
    'Melde dich einfach mit deiner Smile-ID an und werde Teil von joviva!',
  buttonLabel: 'Login mit Smile-ID',
  connectionErrorTitle: 'Es ist ein Fehler aufgetreten',
  dataProtection: 'Datenschutz',
  imprint: 'Impressum',
  agb: 'AGB',
  knowMoreAboutJoviva: 'Mehr über mein joviva erfahren',
  registerAsMember: (
    <>
      Du möchtest bei joviva teilnehmen? Hier als Sanitätshaus{' '}
      <a
        href={
          'https://www.sani-aktuell.de/mitglied-werden/#1597322703756-20c8785b-32f5'
        }
        target={'_blank'}
        rel={'noreferrer'}
        className={classNames(
          focusRing,
          'curser:pointer underline-position-under underline',
        )}
      >
        registrieren.
      </a>
    </>
  ),
}
type LoaderReturnType = {
  connectionError: ClearedErrorData | undefined
}

export async function loader({ request }: LoaderFunctionArgs) {
  await requireNotAuthenticated(request)
  let connectionError: LoaderReturnType['connectionError'] = undefined
  const errorCode = new URL(request.url).searchParams.get('error')
  if (errorCode && isErrorCodeKnown(errorCode)) {
    connectionError = {
      code: errorCode,
      message: getApiErrorMessage(errorCode),
    }
  }

  return {
    connectionError,
  }
}

export default function Login() {
  const { connectionError } = useLoaderData() as LoaderReturnType
  return (
    <PageLayoutWithoutHeader background={<BackgroundElements />}>
      <div className={'flex flex-1 items-center'}>
        <div className={defaultGrid}>
          <div className={gridChildClasses.narrow4}>
            <JovivaLogo className='w-72 max-w-full' />
            <h1 className='text-color-heading mt-4 text-h3 md:mt-6 md:text-h2 lg:text-h1'>
              {strings.headline}
            </h1>
            <p
              className={classNames(
                gridChildClasses.narrow4,
                'mt-4 text-body1',
              )}
            >
              {strings.description}
            </p>

            {connectionError && (
              <div className='mt-8'>
                <ActionErrorAlert data={connectionError} />
              </div>
            )}
            <div className={classNames('mt-10 flex text-body1 sm:mt-8')}>
              <ButtonLink
                className='w-full'
                to={ROUTES.API.AUTH.LOGIN.$buildPath({})}
              >
                {strings.buttonLabel}
              </ButtonLink>
            </div>
            <div className='my-8 h-[1px] w-full bg-grey-400'></div>
            <div className={'flex flex-col items-center justify-center'}>
              <a
                href={'https://www.joviva.de/mein-joviva'}
                target={'_blank'}
                rel={'noreferrer'}
                className={classNames(
                  focusRing,
                  'curser:pointer flex items-center justify-center text-body4 text-grey-700',
                )}
              >
                {strings.knowMoreAboutJoviva}
                <ExternalLinkIcon
                  className={classNames(buttonIconClasses.right, 'h-4 w-4')}
                />
              </a>
              <p
                className={classNames(
                  focusRing,
                  'mt-4 text-caption2 text-grey-600',
                )}
              >
                {strings.registerAsMember}
              </p>
            </div>
            <div className='my-8 h-[1px] w-full bg-grey-400'></div>
            <img
              src={SANI_AKTUELL_LOGO}
              alt={'Sanitätshaus Aktuell Logo'}
              className={'mx-auto max-h-16'}
              aria-hidden
            />
            <div
              className={classNames(
                'mt-6 flex justify-evenly text-body1 text-grey-600',
              )}
            >
              <a
                className={focusRing}
                href={'https://www.joviva.de/datenschutz'}
              >
                {strings.dataProtection}
              </a>
              <a className={focusRing} href={'https://www.joviva.de/impressum'}>
                {strings.imprint}
              </a>
              <a className={focusRing} href={'https://www.joviva.de/agb'}>
                {strings.agb}
              </a>
            </div>
          </div>
        </div>
      </div>
    </PageLayoutWithoutHeader>
  )
}
